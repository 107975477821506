@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  a {
    @apply text-blue-600 underline;
  }
}

@layer components {
  .btn {
    @apply inline-block px-5 py-3 mt-4 text-sm font-semibold tracking-wider uppercase rounded-lg shadow-lg sm:text-base;
  }
  .btn:focus {
    @apply shadow-lg outline-black;
  }

  .btn-indigo {
    @apply text-white bg-indigo-500;
  }
  .btn-indigo:hover {
    @apply bg-indigo-400;
  }
  .btn-indigo:active {
    @apply bg-indigo-600;
  }

  .btn-gray {
    @apply text-white bg-gray-400;
  }
  .btn-gray:hover {
    @apply bg-gray-300;
  }
  .btn-gray:active {
    @apply bg-gray-500;
  }
}

@layer utilities {
  /* body {
    background-color: yellow;
  } */
  li {
    list-style-type: none;
  }
}
